import Head from 'next/head';
import { useEffect } from 'react';

export default function CustomHead({ title }) {
  
  // Change favicon
  useEffect(() => {
    const currentPage = window.location.pathname;

    let newFavicon;
    if (currentPage === '/index') {
      newFavicon = '/favicon_main.ico';
    } else if (currentPage === '/conselhos') {
      newFavicon = '/favicon_conselhos.ico';
    } else if (currentPage === '/estagios') {
      newFavicon = '/favicon_estagios.ico';
    } else {
      newFavicon = '/favicon_main.ico'; 
    }

    const favicon = document.getElementById('favicon');
    if (favicon) {
      favicon.href = newFavicon;
    }
  }, []);

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="description" content="A plataforma de vagas especialista em conselhos" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon_main.ico" id="favicon" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
        <link
          href="https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&family=Encode+Sans+Expanded:wght@100;200;300;400;500;600;700;800;900&display=swap"
          rel="stylesheet"
        />
      </Head>
    </>
  );
}
