import '@/styles/globals.css'
import MainLayout from '../components/layout/_main-layout'
import { React, useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function App({ title, Component, pageProps }) {
  useEffect(() => {  
    AOS.init({
         duration: 800,
         once: false,
       })
 }, [])
  return (
    <MainLayout>
      <Component {...pageProps} />
    </MainLayout>
)}
